<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="seachStatus" status="my-orange" @click="showSearch(false)">隐藏查询</vxe-button>
        <vxe-button v-if="!seachStatus" status="my-orange" icon="fa fa-search" @click="showSearch(true)">查询</vxe-button>
        <vxe-button v-permission="'ManManageExportButton'" status="my-orange" icon="fa fa-plus" @click="downloadExcle">导出</vxe-button>
        <vxe-button v-permission="'AddPersonnelManage'" status="my-orange" icon="fa fa-plus" @click="goPage('add')">新增</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="seachStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="searchEvent" @reset="searchReset">
        <!-- address string 机构地址 ageFrom integer($int32) 年龄区间 ageTo
        integer($int32) 年龄区间 area string 区 city string 市 idNum string
        身份证 name string 姓名 sex string 性别 street string -->
        <vxe-form-item title="姓名" span="8">
          <vxe-input v-model="searchData.name" placeholder="请输入姓名" clearable />
        </vxe-form-item>

        <!-- <vxe-form-item title="机构地址" span="8">
          <vxe-input
            v-model="searchData.address"
            placeholder="请输入机构地址"
            clearable
          ></vxe-input>
        </vxe-form-item> -->
        <vxe-form-item title="年龄" span="8">
          <vxe-input v-model="searchData.ageFrom" style="width: 50%; display: inline-block" placeholder="请输入年龄区间" auto-complete="off" />
          <vxe-input v-model="searchData.ageTo" style="width: 50%; display: inline-block" placeholder="请输入年龄区间" auto-complete="off" />
        </vxe-form-item>
        <vxe-form-item title="性别" span="8">
          <vxe-select v-model="searchData.sex" placeholder="请选择性别" style="width: 100%">
            <vxe-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value" />
          </vxe-select>
        </vxe-form-item>

        <vxe-form-item title="身份证号" span="8">
          <vxe-input v-model="searchData.idNum" placeholder="请输入身份证号" auto-complete="off" />
        </vxe-form-item>
        <vxe-form-item title="所属区域">
          <vxe-select v-model="searchData.area" placeholder="请选择区县" style="width: 100%" @change="areaChange">
            <vxe-option v-for="item in areaList" :key="item" :label="item" :value="item" />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item label-width="0">
          <vxe-select v-model="searchData.street" placeholder="请选择街道" style="width: 100%">
            <vxe-option v-for="item in streetList" :key="item" :label="item" :value="item" />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item label-width="0">
          <vxe-input v-model="searchData.address" placeholder="请输入详细地址" auto-complete="off" />
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <div class="table">
      <vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
        <template v-slot:personName="{ row }">
          <p class="link-a" @click="goPage('detail', row)">
            {{ row.personName }}
          </p>
        </template>
        <template v-slot:leaveTime="{ row }">
          <span>{{ $tools.reversalTime(row.leaveTime) }}</span>
        </template>
        <template v-slot:factTime="{ row }">
          <span>{{ $tools.reversalTime(row.factTime) }}</span>
        </template>
        <template v-slot:operation="{ row }">
          <vxe-button v-permission="'AddPersonnelManage'" type="text" status="primary" @click="goPage('edit', row)">修改</vxe-button>
          <vxe-button v-permission="'ManManageDeleteButton'" type="text" status="primary" @click="remove(row.id)">删除</vxe-button>
        </template>
      </vxe-grid>
      <!-- 分页 -->
      <div class="page">
        <vxe-pager
          :current-page.sync="tablePage.currentPage"
          :page-size.sync="tablePage.pageSize"
          :page-sizes="tablePage.pageSizes"
          :total="tablePage.totalResult"
          :layouts="layouts"
          @page-change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data() {
    return {
      sexList: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ],
      areaList: [],
      streetList: [],
      loading: false,
      seachStatus: false,
      searchData: {
        city: '西安'
      },
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      //  序号、姓名、性别、年龄、用餐次数、住址 、联系方式、操作（修改、删除）
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 50,
          align: 'center'
        },
        {
          field: 'personName',
          title: '姓名',
          showOverflow: true,
          align: 'center',
          minWidth: '200',
          slots: {
            default: 'personName'
          }
        },
        {
          field: 'personSex',
          title: '性别',
          showOverflow: true,
          align: 'center',
          minWidth: '60'
        },
        {
          field: 'age',
          title: '年龄',
          showOverflow: true,
          align: 'center',
          minWidth: '60'
        },
        {
          field: 'mealTimes',
          title: '用餐次数',
          showOverflow: true,
          align: 'center',
          minWidth: '60'
        },
        {
          field: 'address',
          title: '住址',
          showOverflow: true,
          align: 'center',
          minWidth: '140'
        },
        {
          field: 'personTel',
          title: '联系方式',
          showOverflow: true,
          align: 'center',
          minWidth: '120'
        },
        {
          title: '操作',
          width: 140,
          showOverflow: true,
          slots: {
            default: 'operation'
          }
        }
      ],
      tableData: []
    }
  },
  computed: {
    ...mapState(['layouts']),
    ...mapGetters(['seqId'])
  },
  watch: {
    seqId() {
      // this.getLists()
    }
  },
  created() {
    this.initView()
    this.getAreaList()
  },
  methods: {
    ...mapActions(['getManInfoList', 'delMealPerson', 'getArealistServe', 'getstrealistServe']),
    initView() {
      // this.getTeamList();
      // this.getCategoryList();
      this.getLists()
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    downloadExcle() {
      // 导出
    },
    // 获取区县list
    getAreaList() {
      this.getArealistServe().then((res) => {
        if (res.code == 200) {
          this.areaList = res.data
        }
      })
    },
    areaChange(val) {
      if (val) {
        this.getStreetList(this.searchData.area)
      }
    },
    // 获取街道list
    getStreetList(val) {
      const params = {
        areaName: val
      }
      this.getstrealistServe(params).then((res) => {
        if (res.code == 200) {
          this.streetList = res.data
        }
      })
    },
    goPage(type, itemData) {
      if (type == 'detail') {
        this.$router.push({
          name: 'ManManageDetail',
          params: {
            type: type,
            id: itemData.id
          }
        })
      } else {
        this.$router.push({
          name: 'AddPersonnelManage',
          params: {
            type: type,
            id: type == 'add' ? 'add' : itemData.id
          },
          query: {
            item: type == 'add' ? 'add' : JSON.stringify(itemData)
          }
        })
      }
    },
    getLists() {
      this.loading = true
      this.getManInfoList({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: this.searchData
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      console.log(this.searchData)
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {}
      this.getLists()
    },
    remove(id) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          console.log('执行删除操作')
          this.delete(id)
        }
      })
    },
    delete(id) {
      this.delMealPerson({
        id: id
      }).then((res) => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: 'success'
          })
          this.getLists()
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  overflow-y: auto;
}
.wrapper {
  .datetime {
    display: flex;
    align-items: center;
    span {
      margin: 0 3px;
    }
  }
}
.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
